<template>
  <div class="user-cart-partner-clothing-item"
       :class="$mq">
    <div class="clothing-item-preview">
      <img :src="clothingItem.mainImage.middle.url">
    </div>
    <div class="clothing-item-data">
      <a class="title"
         v-if="clothingItem.partner.is_available"
         target="_blank"
         :href="clothingItem.partnerUrl">
        {{ clothingItem.brand.title }} / {{ clothingItem.title }}
      </a>
      <div class="title title-without-link"
           v-else>
        {{ clothingItem.brand.title }} / {{ clothingItem.title }}
      </div>

      <ul class="characteristics">
        <li class="characteristic">
          <div class="name">{{ $t('cart.clothingItem.brand') }}</div>
          <div class="value">
            {{ clothingItem.brand.title }}
          </div>
        </li>
        <li class="characteristic">
          <div class="name">{{ $t('cart.clothingItem.partner') }}</div>
          <div class="value">
            <a v-if="clothingItem.partner.is_available"
               :href="clothingItem.partner.partner_url">
              {{ clothingItem.partner.title }}
            </a>
            <template v-else>
              {{ clothingItem.partner.title }}
            </template>
          </div>
        </li>
        <li class="characteristic">
          <div class="name">{{ $t('cart.clothingItem.price') }}</div>
          <div class="value">
            {{ clothingItem.price | currency }} {{ clothingItem.currencySymbol }}
          </div>
        </li>
      </ul>

      <div class="actions">
        <div class="action"
             @mouseover="isLikeIconHovered = true"
             @mouseleave="isLikeIconHovered = false"
             @click="onLike">
          <base-icon-like :active="liked || isLikeIconHovered"/>
          <span>{{ $t('cart.clothingItem.favorite') }}</span>
        </div>
        <div class="action" @click="$emit('remove')">
          <base-icon icon="remove"></base-icon>
          <span>{{ $t('cart.clothingItem.remove') }}</span>
        </div>
        <div class="action"
             v-if="clothingItem.partner.is_available"
             @click="openPartner">
          <base-icon icon="priceTag"></base-icon>
          <span>
          {{ $t('studio.catalogue.singleGood.buy') }}
        </span>
        </div>
      </div>
    </div>

    <div class="clothing-item-price" v-if="$mq === 'desktop'">
      {{ clothingItem.price | currency }} {{ clothingItem.currencySymbol }}
    </div>
  </div>
</template>

<script>
  import clothingItemService from '@/services/queries/clothingItemQueries'
  import { authenticated } from '@/services/auth'

  export default {
    props: {
      clothingItem: {
        required: true,
        type: Object
      }
    },

    data() {
      return {
        liked: this.clothingItem.liked,
        isLikeIconHovered: false
      }
    },

    methods: {
      onLike() {
        if (!authenticated()) {
          this.openAuthModal({ content: 'login' })
          return
        }

        if (!this.liked) {
          clothingItemService.like(this.clothingItem.id).then(response => {
            this.updateClothingItem({
              id: this.clothingItem.id,
              data: {
                liked: true
              }
            })
          })
        } else {
          clothingItemService.removeLike(this.clothingItem.id).then(response => {
            this.updateClothingItem({
              id: this.clothingItem.id,
              data: {
                liked: false
              }
            })
          })
        }

        this.liked = !this.liked
      },

      openPartner() {
        window.open(this.clothingItem.partner_side_info.url, '_blank')
      },

      ...mapActions('system', [
        'openAuthModal'
      ]),

      ...mapActions('cart', [
        'updateClothingItem'
      ])
    }
  }
</script>

<style lang="scss" scoped>


  .user-cart-partner-clothing-item {
    display: flex;
    padding: 20px;

    &.mobile {
      flex-direction: column;
      padding: 0;

      .clothing-item-data {
        padding: 0;
        margin-bottom: 20px;
      }

      .clothing-item-data .characteristics {
        margin-bottom: 20px;
      }

      .clothing-item-data .actions .action {
        margin-right: 10px;
      }

      .clothing-item-price {
        margin-bottom: 20px;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid #F4F4F4;
    }

    .clothing-item-preview {
      flex-basis: 110px;
      flex-shrink: 0;

      img {
        width: 100%;
      }
    }

    .clothing-item-data {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding-left: 40px;

      .title {
        font-size: 16px;
        margin-bottom: 10px;
        flex-basis: 30px;
        flex-shrink: 0;

        &.title-without-link {
          color: #727272;
        }
      }

      .characteristics {
        flex: 1 0;
        list-style-type: none;
        margin: 0 0 40px 0;
        padding: 0;
      }

      .characteristic {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .name {
          flex-basis: 100px;
          font-size: 12px;
          color: #B7B7B7;
        }

        .value {
          font-size: 14px;
          color: #797979;

          a {
            font-size: 14px;
          }
        }
      }

      .actions {
        display: flex;
        flex-basis: 20px;
        margin-top: auto;

        .action {
          display: flex;
          align-items: center;
          margin-right: 20px;

          font-size: 12px;
          letter-spacing: 0.86px;
          color: #C1C1C1;
          fill: #C1C1C1;

          cursor: pointer;

          & > span {
            transition: all 0.3s;
          }

          &:hover > span {
            color: $primary-color;
          }

          & > span {
            margin-left: 10px;
          }
        }
      }
    }

    .clothing-item-price {
      font-family: CormorantGaramond-SemiBold, serif;
      font-size: 22px;
      color: #282828;
      letter-spacing: 0;
      line-height: 22px;
    }
  }
</style>
