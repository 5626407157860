<template>
  <div class="user-cart container"
       :class="$mq">
    <user-cart-partner v-for="item in groupedClothingItems"
                       :key="item.partner.partner_id"
                       :partner="item.partner"
                       :clothingItems="item.clothingItems"></user-cart-partner>
    <div class="total">
      <div class="title">{{ $t('cart.total') }}</div>
      <div class="value">{{ totalSum | currency }} {{ currencySymbol }}</div>
    </div>
  </div>
</template>

<script>
import UserCartPartner from './UserCartPartner.vue'
import hasBackgroundMixin from '@/services/mixins/hasBackgroundMixin.js'

export default {
  components: {
    UserCartPartner
  },

  mixins: [hasBackgroundMixin],

  computed: {
    totalSum () {
      let total = 0

      this.groupedClothingItems.forEach(item => {
        total += item.clothingItems.reduce((sum, item) => sum + item.local_price, 0)
      })

      return total
    },

    currencySymbol () {
      return this.groupedClothingItems[0]?.clothingItems[0]?.currencySymbol
    },

    ...mapGetters('cart', {
      groupedClothingItems: 'getClothingItemsGroupedByPartnerId'
    })
  }
}
</script>

<style lang="scss" scoped>
  .user-cart {
    margin-top: 20px;

    &.mobile {
      margin: 20px;

      .total {
        padding: 20px;
      }
    }

    & > .total {
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 20px 40px;
      margin-bottom: 40px;

      background: #fff;
      box-shadow: 0 6px 10px 0 rgba(240,240,240,0.50);
      border-radius: 6px;

      .title {
        font-size: 14px;
        color: #797979;
        letter-spacing: 0;
        line-height: 20px;
      }

      .value {
        font-weight: bold;
        font-size: 26px;
        color: $primary-color;
        letter-spacing: -0.35px;
      }
    }
  }
</style>
