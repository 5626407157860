<template>
  <div class="user-cart-partner"
       :class="$mq">
    <div class="heading">
      <div class="partner-title">{{ partner.title }}</div>
      <div class="partner-remove">
        <base-icon :title="$t('cart.removeFromPartner', { partnerName: partner.title })"
                   icon="remove"
                   @click="removeAll(clothingItems)"
                   v-tippy></base-icon>
      </div>
    </div>

    <user-cart-partner-clothing-item v-for="clothingItem in clothingItems"
                                     :key="clothingItem.id"
                                     :clothing-item="clothingItem"
                                     @remove="remove(clothingItem)">
    </user-cart-partner-clothing-item>

    <div class="total">
      <div class="value">
        <div class="title">{{ $t('cart.totalInPartner', { partnerName: partner.title }) }}</div>
        <div class="sum">{{ totalSum(clothingItems) | currency }} ₽</div>
      </div>
      <base-button class="go-to-partner"
                   v-if="partner.is_available"
                   @click="goToPartner(partner)">
        {{ $t('cart.goToPartner') }}
      </base-button>
    </div>
  </div>
</template>

<script>
  import UserCartPartnerClothingItem from './UserCartPartnerClothingItem.vue'

  export default {
    props: {
      partner: {
        required: true,
        type: Object
      },

      clothingItems: {
        required: true,
        type: Array
      }
    },

    components: {
      UserCartPartnerClothingItem
    },

    methods: {
      goToPartner(partner) {
        window.open(partner.partner_url)
      },

      totalSum() {
        return this.clothingItems.reduce((sum, item) => sum + item.price, 0)
      },

      remove(clothingItem) {
        this.removeClothingItem(clothingItem)
      },

      removeAll(clothingItems) {
        clothingItems.forEach(item => this.removeClothingItem(item))
      },

      ...mapActions('cart', [
        'removeClothingItem'
      ])
    }
  }
</script>

<style lang="scss" scoped>


  .user-cart-partner {
    background: #fff;
    box-shadow: 0 6px 10px 0 rgba(240,240,240,0.50);
    border-radius: 8px;
    padding: 20px 40px;
    margin-bottom: 40px;

    &.mobile {
      padding: 20px;

      .total {
        flex-direction: column;

        .value {
          margin-bottom: 20px;
        }
      }

      .total .go-to-partner {
        width: 100%;
      }
    }

    .heading {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #F4F4F4;
      padding: 10px 0;
      margin-bottom: 20px;

      .partner-title {
        font-family: CormorantGaramond-SemiBold, serif;
        font-size: 22px;
        color: #282828;
        letter-spacing: 0;
        line-height: 22px;
      }

      .partner-remove {
        display: flex;
        align-items: center;
        cursor: pointer;
        fill: #C1C1C1;

        & > svg {
          transition: all 0.3s;
        }

        &:hover > svg {
          fill: $primary-color;
        }
      }
    }

    & > .total {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      .value {
        .title {
          font-size: 14px;
          color: #797979;
        }

        .sum {
          font-family: CormorantGaramond-SemiBold, serif;
          font-size: 22px;
          color: #282828;
        }
      }

      .go-to-partner {
        width: 250px;
      }
    }
  }
</style>
